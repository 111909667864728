<template>
  <div>
    <b-row class="background">
      <b-col md="12">
        <!-- NavBar-->
        <b-navbar toggleable="lg" class="navbar-bg">
          <b-navbar-brand @click="$router.push('/')">
            <b-img
              src="@/assets/images/logo/YBM.gif"
              class="logo-img"
              width="220"
              height="90"
            >
            </b-img>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse
            class="text-align-right justify-content-right"
            id="nav-collapse"
            is-nav
          >
            <b-navbar-nav>
              <b-nav-item class="nav-link" @click="$router.push('/about')">
                {{ getNavItemText("HAKKIMIZDA") }}
              </b-nav-item>
              <b-nav-item class="nav-link" @click="$router.push('/sektorler')">
                {{ getNavItemText("SEKTÖRLER") }}
              </b-nav-item>
              <b-nav-item class="nav-link" @click="$router.push('/urunler')">
                {{ getNavItemText("ÜRÜNLER") }}
              </b-nav-item>
              <b-nav-item class="nav-link" @click="$router.push('/uretim')">
                {{ getNavItemText("ÜRETİM") }}
              </b-nav-item>
              <b-nav-item class="nav-link" @click="$router.push('/kalite')">
                {{ getNavItemText("KALİTE") }}
              </b-nav-item>
              <b-nav-item class="nav-link" @click="$router.push('/iletisim')">
                {{ getNavItemText("İLETİŞİM") }}
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>

          <!-- Dil Checkbox -->
          <b-form-checkbox
            name="check-button"
            switch
            v-model="selected"
            class="custom-control-danger"
            @change="onLanguageChange"
          >
            {{ options[0].text }}
          </b-form-checkbox>
        </b-navbar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BCollapse,
  BNavbarNav,
  BNav,
  BNavItem,
  BImg,
  BFormCheckbox,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";

export default {
  components: {
    BRow,
    BCol,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BNav,
    BNavItem,
    BImg,
    BFormCheckbox,
    BFormGroup,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      options: [{ text: "EN" }],
      checkboxState: [],
      selected: localStorage.getItem("language") === "2" ? true : false,
    };
  },
  mounted() {
    this.selected = localStorage.getItem("language") === "2" ? true : false;
  },
  methods: {
    onLanguageChange() {
      const languageCode = this.selected ? 2 : 1;
      console.log("langCode " + languageCode);
      axios
        .get(`api/Contents/GetListByLanguage?language=${languageCode}`)
        .then((response) => {
          this.checkboxState = response.data.data;
          console.log("API'den gelen veri:", response.data);
          localStorage.setItem("language", languageCode.toString());
          location.reload();
        })
        .catch((error) => {
          console.error("API hatası:", error);
        });
    },

    getNavItemText(route) {
      return this.selected
        ? this.getEnglishText(route)
        : this.getDefaultText(route);
    },

    getEnglishText(route) {
      switch (route) {
        case "HAKKIMIZDA":
          return "ABOUT US";
        case "SEKTÖRLER":
          return "SECTORS";
        case "ÜRÜNLER":
          return "PRODUCTS";
        case "ÜRETİM":
          return "PRODUCTION";
        case "KALİTE":
          return "QUALITY";
        case "İLETİŞİM":
          return "CONTACT";
        default:
          return route; // If no translation is available, return the original route
      }
    },

    getDefaultText(route) {
      return route; // Use the route as the default text
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap"); /* Navbar için stil */
.nav-link {
  position: relative;
  z-index: 1;
  color: rgba(0, 0, 0, 0.726) !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
.nav-link:hover {
  background-color: #a7b8c7;
  border-radius: 3px;
}
.navbar-bg {
  width: 100%;
  z-index: 9999;
  position: relative;
  left: 0;
  right: 0;
  background: white(255, 255, 255, 0);
}
@media (max-width: 1000px) {
  .navbar-bg {
    background: white;
  }
}
/* Navbar linkleri için stil */
.navbar-bg a {
  position: relative;
  z-index: 1;
  color: rgba(0, 0, 0, 0.726) !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.logo-img {
  margin: 5px 5px;
}
</style>
