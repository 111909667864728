<template>
  <div class="fontBlog">
    <!-- NavBar-->
    <navbar />

    <!-- İmg-->
    <b-img
      class="aboutImg mt-0"
      :src="imgDataUrl"
      fluid
      alt="Responsive image"
    />
    <b-row>
      <b-col md="1"></b-col>
      <b-col md="4">
        <!-- swiper-->
        <swiper
          v-if="groupedData && allDatas"
          :ProductSwiperData="groupedData[17]"
        ></swiper>
      </b-col>

      <b-col md="6">
        <urunler-detail-vue
          v-if="groupedData && allDatas"
          :urunlerData="groupedData[18]"
        />
      </b-col>
      <b-col md="1"></b-col>
    </b-row>
    <footer-vue :AdressData="AdressData"> </footer-vue>
  </div>
</template>

<script>
import { BImg, BRow, BCol } from "bootstrap-vue";
import footerVue from "../home/components/footer.vue";
import Navbar from "../home/components/Navbar.vue";
import swiper from "./swiper.vue";
import urunlerDetailVue from "./urunlerDetail.vue";
import axios from "axios";

export default {
  components: {
    Navbar,
    urunlerDetailVue,
    swiper,
    footerVue,
    BImg,
    BRow,
    BCol,
  },
  data() {
    return {
      imgDataUrl: "",
      allDatas: undefined,
      groupedData: undefined,
      AdressData: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      // console.log("GET DATA");
      this.groupedData = [];
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(
          `/api/Contents/GetListByPageIdAndLanguage?id=8&language=${languageCode}`
        )
        .then((result) => {
          this.getCommunications();
          if (result.data.success) {
            // console.log("BURADA LOGA BAK");
            //    console.log(result.data.data);
            this.allDatas = result.data.data;
            this.splitData(result.data.data);
            //   console.log(this.allDatas[0]);
          } else {
            console.error("API yanıtında başarısızlık:", result.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
    splitData(myData) {
      myData.forEach((item) => {
        //  console.log("fdgdfgd : ",item)
        const componentId = item.componentId;
        if (!this.groupedData[componentId]) {
          this.groupedData[componentId] = [];
        }
        this.groupedData[componentId].push(item);
      });
      this.imgDataUrl = this.groupedData[16][0].image;
      //   console.log(this.groupedData);
    },
    getCommunications() {
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(`/api/Informations/GetListByLanguage?language=${languageCode}`)
        .then((result) => {
          if (result.data.success) {
            this.AdressData = result.data.data;
            //   console.log(this.AdressData)
          } else {
            console.error("API yanıtında başarısızlık:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Dancing+Script&family=Prompt:wght@200&display=swap");
.fontBlog {
  font-family: "Prompt", sans-serif;
  background-color: #8ea2b486 !important;
}
.aboutImg {
  width: 100%;
  height: 700px;
  object-fit: cover;
  object-position: 50% 50%;
}
@media (max-width: 767px) {
  .aboutImg {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
</style>
