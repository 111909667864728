<template>
  <b-row>
      <!-- eslint-disable vue/no-v-html -->
      <div v-for="(product, index) in urunlerData" :key="index">
        <b-card class="urunlerCard mt-5">
          <b-card-text>
            <h2 class="display-4 urunlerTitle mb-0">
              {{ product.title }}
            </h2>
            <hr />
            <div v-html="product.text"></div>
          </b-card-text>
        </b-card>
      </div>
 
  </b-row>
</template>

<script>
import { BImg, BCol, BCard, BRow, BCardText } from "bootstrap-vue";


export default {
  components: {
    BImg,
    BCol,
    BCard,
    BRow,
    BCardText,
  },
  props: {
    urunlerData: Array,
  },
};
</script>

<style scoped>
.urunlerTitle {
  text-align: center;
  color: white;
}
.urunlerCard {
  background-color: #072541d2 !important;
  color: white;
}
</style>
