<template>
  <div class="swiper-container mt-5">
    <swiper
      class="swiper-cube-effect swiper-custom"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide v-for="(data, index) in ProductSwiperData" :key="index">
        <b-img :src="data.image" fluid />
      </swiper-slide>

      <!-- Add Arrows -->
      <div slot="pagination" class="swiper-pagination"></div>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  props: {
    ProductSwiperData: Array,
  },
  data() {
    return {
      swiperOptions: {
        effect: "cube",
        grabCursor: true,
        cubeEffect: {
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
};
</script>
<style scoped>
.swiper-container {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  height: auto;
  border-radius: 10px;
}
</style>
